import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Login from "../pages/Usuarios/Login/Login";

const PublicRoutes = () => {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Login/>}/>
            </Routes>
        </BrowserRouter>

    )
}
export default PublicRoutes;