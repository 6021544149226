import { AxiosError } from "axios";
import {  useNavigate } from "react-router-dom";


export const ErrorInterceptor = (error) =>{

    console.log(error)
 
    //console.log(error)
    if(error.message == 'Network Error'){
        return Promise.reject(new Error('Erro de conexão'))
    }

  
    if(error.code == "ERR_BAD_REQUEST"){
        localStorage.setItem('tk_hsp','')
        window.location.href = '/login';
        return Promise.reject(new Error('Usuário não autrizado'))
    }


console.log(error)
        return Promise.reject(error.response);


}