import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

 const Header = () =>{


    return (
            <Navbar expand="lg"  bg="primary" data-bs-theme="dark">
                <Container fluid={true}>
                    <Navbar.Brand href="#inicio">HospPET</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/inicio">Inicio</Nav.Link>
                            <Nav.Link href="/clientes">Clientes</Nav.Link>
                            <NavDropdown title="Configurações" id="basic-nav-dropdown"> 
                                <NavDropdown.Item href="/configuracoes/usuarios">Usuários</NavDropdown.Item>
                                <NavDropdown.Item href="#">item </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        
                    </Navbar.Collapse>
                </Container>
</Navbar>
    )


}

export default Header;