import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';


const AnimaisCardComp = ({ animal }) => {



    const concatWords = (arrayWords) => {

        return arrayWords.filter((a) => { return a != undefined }).join(', ')
    }
    return (
        <Card >

            <Card.Header>
                <Row>
                    <Col xs={4} sm={3} md={2} lg={1} style={{paddingLeft:0}}>
                        <Image src='https://institutopetbrasil.com/wp-content/uploads/2019/11/boas_praticas.jpg' roundedCircle style={{ maxWidth: '59px', width: '100%' }} />
                    </Col>
                    <Col  xs={8} sm={8} style={{paddingLeft:0}}>
                        <div className='bold text-primary' > {animal?.nome} </div>
                        {concatWords([animal?.especies?.nome, animal?.racas?.nome, animal?.portes?.nome])}

                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {animal?.observacao}
                </Card.Text>
                <div className='pull-right'>
                    <Button variant="primary" size="sm" title='Editar' className='mr-10'><FontAwesomeIcon icon={faPenToSquare} /></Button>
                    <Button variant="info" size="sm" title='Ver ficha'><FontAwesomeIcon icon={faEye} /></Button>
                </div>

            </Card.Body>
        </Card>
    )
}


export default AnimaisCardComp; 