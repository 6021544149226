import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Login from "../pages/Usuarios/Login/Login";
import Home from "../pages/Home";
import ClienteCadEdit from "../pages/Clientes/ClienteCadEdit";
import Clientes from "../pages/Clientes/Clientes";
import AnimaisCadEdit from "../pages/Animais/AnimaisCadEdit";
import Usuarios from "../pages/Usuarios/Usuarios";
import ClientesDados from "../pages/Clientes/ClienteDados";
import UsuariosCadEdit from "../pages/Usuarios/UsuarioCadEdit";

const PrivateRoutes = () => {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/inicio" element={<Home />} />

                <Route path="/clientes/:idCliente" element={<ClientesDados />} />
                <Route path="/clientes/cadEdit/:idCliente" element={<ClienteCadEdit />} />
                <Route path="/clientes/cadEdit" element={<ClienteCadEdit />} />
                <Route path="/clientes" element={<Clientes />} />

                <Route path="/animais" />
                <Route path="/animais/cadEdit/:idCliente" element={<AnimaisCadEdit />} />
                <Route path="/animais/cadEdit" element={<AnimaisCadEdit />} />


                <Route path="/configuracoes/usuarios/cadEdit/:idUsuario" element={<UsuariosCadEdit />} />
                <Route path="/configuracoes/usuarios/cadEdit" element={<UsuariosCadEdit />} />
                <Route path="/configuracoes/usuarios" element={<Usuarios />} />

                <Route path="*" element={<Navigate to="/inicio" />} />
            </Routes>
        </BrowserRouter>

    )
}
export default PrivateRoutes;