
const Footer  =() =>{


    return(
      <div>
        Footer
      </div>
    )
    
    }
    
    export default Footer;