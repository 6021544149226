import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Template from "../../layout/Template";
import { ClienteService } from "../../services/api/Clientes/ClienteService";
import { useEffect, useState } from "react";
import './cliente.css'
import { useParams } from "react-router-dom";
import { faChevronLeft, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimaisCardComp from "../../components/Animais/AnimaisCardComp";

const ClientesDados = () => {

    const { idCliente } = useParams()
    const [cliente, setCliente] = useState(null)
    console.log(idCliente)
    useEffect(() => {

        const fetchData = async () => {
            const res = await ClienteService.getById(idCliente)
            console.log(res)
            if (res.success) {
                setCliente(res.data);
            } else {
                setCliente(null)
            }

        }

        fetchData()
    }, [])

    return (

        <>
            <Template>
                <h3 className="text-info"> Clientes
                    <Button variant="danger" size="sm" style={{ marginLeft: '15px' }} href="/clientes"><FontAwesomeIcon icon={faChevronLeft} /> Voltar</Button>
                </h3>
                <hr></hr>
                <Container fluid id="cliente-header">
                    <Row>

                    </Row>
                    <Row>
                        <Col>
                            <h4 className="text-default"><span className="mr-10">{cliente?.nome} {cliente?.sobrenome} </span>
                            <Button variant="primary" size="sm" href={'/clientes/cadEdit/' + idCliente}><FontAwesomeIcon icon={faPenToSquare} /> Editar</Button>

                            </h4>

                        </Col>
                        <Col>
                            <div>
                                <label className="text-default">Observações:</label> {cliente?.obervacoes}
                            </div>
                            <div>
                                <label className="text-default">Alerta:</label> <span className="text-danger"> {cliente?.alerta}</span>
                            </div>
                        </Col>
                    </Row>
                </Container>









                <Row>
                    {cliente?.animais?.map((animal) => (
                        <Col sm={4}  xs={12} md={4} lg={3} style={{marginBottom:'15px'}}>
                            <AnimaisCardComp animal={animal} />
                        </Col>
                    ))}

                </Row>



            </Template>

        </>


    )
}

export default ClientesDados;