import React from 'react'
import { Button, Modal } from 'react-bootstrap';


const ModalSimNao = ({ children, show, handleClose, handleConfirm }) => {


    return (

        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Body className='text-center' style={{ fontSize: '15px' }}>
                <div className='alert alert-danger'>
                    {children}
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <Button variant='danger' size='xs' onClick={() => handleClose()}>Não</Button>
                <Button variant='primary' size='xs' onClick={() => handleConfirm()}>Sim</Button>
            </Modal.Footer>
        </Modal>
    )
}


export default ModalSimNao;