import React, { useContext, useEffect } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";


/***
 * Criar aqui uma verificação para saber se  usuário está logado veirificando  o token no local storage
 * 
 */

const AppRoutes = () => {

    
    const { auth, setAuth, user } = useContext(AuthContext)
    const PrivateRoutesAuth = () =>{
        console.log(localStorage.getItem('tk_hsp')) 
        if (localStorage.getItem('tk_hsp') !== 'null' && localStorage.getItem('tk_hsp') !== '') {
            setAuth(true);
            return <PrivateRoutes />
        }else{
            setAuth(false);
            return <PublicRoutes />
        }

    }


    return PrivateRoutesAuth();


}

export default AppRoutes;