import react, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import AnimaisClientesList from './AnimaisClientesList'
import AnimaisCadEdit from './AnimaisCadEdit';
import { useParams } from 'react-router-dom';


const AnimaisClientes = () => {

    const { idCliente } = useParams();
    const [showFormCad, setShowFormCad] = useState(false);
    const [idAnimal, setIdAnimal] = useState(null)


    useEffect(() => {
        if (!showFormCad) {
            setIdAnimal(null)
        }

    }, [showFormCad])


    const handleNovaAnimal = () => {
        setShowFormCad(true)
    }





    return (
        <>

            {((showFormCad) ?
                <AnimaisCadEdit setShowFormCad={setShowFormCad} idCliente={idCliente} idAnimal={idAnimal} />
                :
                <div >

                    <br></br>
                    <Button variant='info' onClick={() => handleNovaAnimal()} className='mb-10'>+ Novo</Button>
                    <AnimaisClientesList idCliente={idCliente} 
                    setShowFormCad={setShowFormCad} 
                    setIdAnimal={setIdAnimal}
                     showFormCad={showFormCad} />
                    <div className='text-center mt-15'>
                        <Button variant='danger' href='/clientes'>Voltar</Button>
                    </div>
                </div>
            )}


         
        </>
    )


}
export default AnimaisClientes