import AppRoutes from "./routes/AppRoutes";
import './assets/css/custom.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import AuthProvider from "./Contexts/AuthContext";
const App = () => {
  return (
    <div>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>

    </div>
  );
}

export default App;
