


export const dateBrToDb = (date) => {
    date = date.split('/').reverse().join('-')
    return date;
}

export const dateDbToBr = (date) => {
    date = date.split('-').reverse().join('/')
    return date;
}

export const limpaTelefone = (numero)=>{

    return numero.replace(/[\(\)\.\s-]+/g, '');

}

export const Funcoes = {
    dateBrToDb,
    dateDbToBr,
    limpaTelefone
}
