
import Api from "../Api"




const getAll = async () => {

    try {
        const { data } = await Api.get('/animais')
        return data;
    } catch (error) {
        console.log(error)
    }
}


const store = async (dataForm) => {

    try {
        const { data } = await Api.post('/animais', dataForm)
        return data;
    } catch (error) {
        console.log(error)
    }


}

const getById = async (id) => {
    try {
        const { data } = await Api.get('/animais/' + id)
        return data;

    } catch (error) {
        console.log(error)
    }
}


const getAllByClienteId = async (clienteId) => {
    try {
        const { data } = await Api.get('/animais/getByCliente/' + clienteId)
        return data;

    } catch (error) {
        console.log(error)
    }
}

const update = async (id, dataForm) => {
    try {
        const { data } = await Api.put('/animais/'+id, dataForm);
        return data;
    } catch (error) {
        console.log(error)
    }

}

const deleteById = async (id) => {
    try {
        const { data } = await Api.delete('/animais/' + id);
        return data;
    } catch (error) {
        console.log(error)
    }

}



export const AnimaisService =  {
    getAll,
        store,
        getAllByClienteId,
        update,
        deleteById,
        getById
}
