import React from "react";

import Template from '../layout/Template'
const Home = () =>{

    return (
        <>
         <Template>
              <h3>Inicio</h3>
              
         </Template>
     
        </>
    )
}
export default Home;