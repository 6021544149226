

const getOptionsSexoAnimais = () => {
    return [{
        'id': 'M',
        'nome': 'Macho'
    }, {
        'id': 'F',
        'nome': 'Fêmea'
    }
    ];
}

const getOptionsPortes = () => {
    return [{
        'id': 9,
        'nome': 'Pequeno'
    }, {
        'id': 10,
        'nome': 'Médio'
    }, {
        'id': 11,
        'nome': 'Grande'
    }, {
        'id': 12,
        'nome': 'Extra Grande'
    },
    ];
}
const getOptionsUF = () =>{

  return    [
    { sigla:'AC', nome: 'Acre' },
    { sigla:'AL', nome: 'Alagoas' },
    { sigla:'AP', nome: 'Amapá' },
    { sigla:'AM', nome: 'Amazonas' },
    { sigla:'BA', nome: 'Bahia' },
    { sigla:'CE', nome: 'Ceará' },
    { sigla:'DF', nome: 'Distrito Federal' },
    { sigla:'ES', nome: 'Espírito Santo' },
    { sigla:'GO', nome: 'Goías' },
    { sigla:'MA', nome: 'Maranhão' },
    { sigla:'MT', nome: 'Mato Grosso' },
    { sigla:'MS', nome: 'Mato Grosso do Sul' },
    { sigla:'MG', nome: 'Minas Gerais' },
    { sigla:'PA', nome: 'Pará' },
    { sigla:'PB', nome: 'Paraíba' },
    { sigla:'PR', nome: 'Paraná' },
    { sigla:'PE', nome: 'Pernambuco' },
    { sigla:'PI', nome: 'Piauí' },
    { sigla:'RJ', nome: 'Rio de Janeiro' },
    { sigla:'RN', nome: 'Rio Grande do Norte' },
    { sigla:'RS', nome: 'Rio Grande do Sul' },
    { sigla:'RO', nome: 'Rondônia' },
    { sigla:'RR', nome: 'Roraíma' },
    { sigla:'SC', nome: 'Santa Catarina' },
    { sigla:'SP', nome: 'São Paulo' },
    { sigla:'SE', nome: 'Sergipe' },
    { sigla:'TO', nome: 'Tocantins' },
    ]
}





export const Commons = {

    getOptionsSexoAnimais,
    getOptionsPortes,
    getOptionsUF
}