import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "react-bootstrap";



const Template  =({children}) =>{


    return(

      <div id='main'>
            <Header></Header>
            <Container fluid  className="container-body">             
                {children}               
            </Container>
        <Footer/>
        </div>
    

    )

}

export default Template;