import React from 'react'
import Api from '../Api'



const getAll = async  (search) =>{
    
try{

    const urlSearch = (search != null)?'?search='+search:'';
    const {data} = await Api.get('/animais/especies'+urlSearch);
    return data;

} catch(error){

}




}





export const AnimaisEspeciesService = {

getAll
}
