import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Template from "../../layout/Template";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { AnimaisPortesService } from "../../services/api/Animais/AnimaisPortesService";
import { AnimaisRacasService } from "../../services/api/Animais/AnimaisRacasService";
import { AnimaisEspeciesService } from "../../services/api/Animais/AnimaisEspeciesService";
import { AnimaisService } from "../../services/api/Animais/AnimaisService";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Commons } from "../../utils/Commons";
import { dateBrToDb, dateDbToBr } from "../../utils/Funcoes";


const AnimaisCadEdit = ({ setShowFormCad, idCliente, idAnimal }) => {

    const scheme = z.object({
        nome: z.string().nonempty('O Nome é obrigatório'),
        sexo: z.string().nullable(),
        clientesId: z.coerce.string(),
        racasId: z.coerce.string().nullable().optional(),
        portesId: z.coerce.string().nullable().optional(),
        especiesId: z.coerce.string().nullable().optional(),
        //  pelagensId: z.coerce.string().nullable(),
        dataNascimento: z.string().nullable(),
        observacao: z.string().nullable().optional(),
       // alerta: z.string().nullable().optional(),
        cor: z.string().nullable().optional(),
    })

    const [alertError, setAlertError] = useState('')
    const [animal, setAnimal] = useState(null);
    const [optionsRacas, setOptionsRacas] = useState([])
    const [optionsEspecies, setOptionsEspecies] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const filterBy = () => true;
    const [selectedRaca, setSelectedRaca] = useState([])

    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({
        mode: 'onBlur',
        resolver: zodResolver(scheme),
        values: animal
    })



    useEffect(() => {

        setAnimal(null)
        setValue('clientesId', idCliente)
        const fetchEspecies = async () => {
            const especies = await AnimaisEspeciesService.getAll()
            if (especies.success) {
                setOptionsEspecies(especies.data)
            }
        }
        fetchEspecies()



        if (idAnimal != null) {

            const fetchAnimal = async () => {
                const AnimalData = await AnimaisService.getById(idAnimal);
                const dados = AnimalData.data;
                console.log(AnimalData)
                if (AnimalData.success) {
                    setAnimal({
                        ...dados,
                        dataNascimento: (dados.dataNasc != null) ? dateDbToBr(dados.dataNasc) : '',
                        especiesId: dados?.especies?.id,
                        portesId: dados?.portes?.id,
                        clientesId: dados.clientesId

                    })
                  if(dados?.racas != null){
                    setSelectedRaca([dados?.racas])
                  }


                }
            }

            fetchAnimal()


        }

    }, [])



    const handleBuscaRaca = async (query) => {
        setIsLoading(true)
        const especiesId = getValues('especiesId')

        const data = await AnimaisRacasService.getAll(query, especiesId)
        //console.log(data.data)
        if (data.success) {
            setOptionsRacas(data.data)
            setIsLoading(false)
        }

    }
    const onSubmit = async (data) => {

        setAlertError('')
        //console.log(data)

        let formData = data;
        if (formData?.dataNascimento != '') {
            formData.dataNascimento = dateBrToDb(formData.dataNascimento)
        }

        let animal =null
        if (idAnimal != null) {
             animal = await AnimaisService.update(idAnimal, data)
        } else {
             animal = await AnimaisService.store(data)
        }


      
        if (animal.success) {
            handleCancel()
        } else {
            setAlertError(animal.msg)
        }


    }

    const handleChangeRacas = (tt) => {


        setSelectedRaca([])
        if (tt.length > 0) {
            setValue('racasId', tt[0].id)
            setSelectedRaca(tt)
        } else {
            setValue('racasId', '')
        }


    }

    const handleCancel = () => {
        reset();
        setShowFormCad(false)
        setAnimal(null)

    }
    //console.log(errors)
    return (
        <>

            <Form>

                <Row >

                    <Form.Group as={Col} controlId="formNome" md={6} >
                        <Form.Label>Nome*</Form.Label>
                        <Form.Control type="text" placeholder="Nome"
                            {...register('nome')}
                            isInvalid={!!(errors?.nome)}
                        ></Form.Control>

                        <Form.Control.Feedback type='invalid'>
                            {errors?.nome?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formSexo" md={4}>
                        <Form.Label>Sexo</Form.Label>
                        <Form.Select {...register('sexo')}>
                            <option value="">Selecione...</option>
                            {Commons.getOptionsSexoAnimais().map((item) => (
                                <option value={item.id}>{item.nome}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formDataNascimento" md={4}>
                        <Form.Label>Data de nascimento</Form.Label>
                        <Form.Control type="text" {...register('dataNascimento')}></Form.Control>
                    </Form.Group>
                </Row>

                <Row>


                    <Form.Group as={Col} controlId="formEspecie" md={4}>
                        <Form.Label>Espécie</Form.Label>
                        <Form.Select {...register('especiesId')}>
                            <option value=''>Selecione...</option>
                            {optionsEspecies?.map((esp) =>
                                <option value={esp.id}>{esp.nome}</option>
                            )
                            }
                        </Form.Select>


                    </Form.Group>

                    <Form.Group as={Col} controlId="formRaca" md={4}>
                        <Form.Label >Raça</Form.Label>
                        <AsyncTypeahead
                      
                            clearButton
                            filterBy={filterBy}
                            id="raca"
                            labelKey="nome"
                            minLength={3}
                            onSearch={handleBuscaRaca}
                            placeholder="Digite a raca"
                            options={optionsRacas}
                            onChange={handleChangeRacas}
                            selected={selectedRaca}
                            renderMenuItemChildren={(option) => (

                                <>
                                    {option.nome}
                                </>

                            )}


                        />
                    </Form.Group>


                    <Form.Group as={Col} controlId="formPorte" md={4}>
                        <Form.Label>Porte</Form.Label>
                        <Form.Select {...register('portesId')}>
                            <option value="">Selecione...</option>
                            {Commons.getOptionsPortes().map((item) => (
                                <option value={item.id}>{item.nome}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>


                    <Form.Group as={Col} md={2}>
                        <Form.Label>Cor:</Form.Label>
                        <Form.Control type="text" {...register('cor')}></Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md={4}>
                        <Form.Label>Observações sobre o animal:</Form.Label>
                        <Form.Control as="textarea" {...register('observacao')}></Form.Control>
                    </Form.Group>
               
                </Row>

                <input type="hidden" {...register('clientesId')} />
            </Form >

            <div className="alert alert-danger text-center" style={{ display: (alertError != '') ? '' : 'none' }}>{alertError}</div>

            <div className="text-center mb-12 col-12" >
                <Button variant="danger" className="mr-15" onClick={() => handleCancel()}>Cancelar</Button>
                <Button variant="primary" onClick={() => handleSubmit(onSubmit)()}>Salvar</Button>
            </div>


        </>
    )

}


export default AnimaisCadEdit;