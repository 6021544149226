import Api from "../Api"



const getAll = async (search) => {

    try {

      
        let urlParams = []
        if (search != undefined && search != null) {
            urlParams.push('search='+search)
        }
        urlParams = urlParams.join('&')
        const { data } = await Api.get('/clientes?' + urlParams)
        if (data) {
            return data;
        }
    } catch (error) {
        console.log(error)
    }



}
const getById = async (idCliente) => {

    try {
        const { data } = await Api.get('/clientes/' + idCliente)
        if (data) {
            return data;
        }
    } catch (error) {
        console.log(error)
    }

}
const store = async (dataForm) => {


    try {
        const { data } = await Api.post('/clientes', dataForm)

        console.log(data)
        return data
    } catch (error) {
        console.log(error)
        return error
    }

}
const updateById = async (idcliente, dataForm) => {
    try {
        const { data } = await Api.put('/clientes/' + idcliente, dataForm)
        return data;
    } catch (error) {
        console.log(error)
        return error
    }


}
const deleteById = async (idCliente) => {

    try {
        const { data } = await Api.delete('/clientes/' + idCliente)
        return data;
    } catch (error) {
        console.log(error)
        return error
    }

}

export const ClienteService = {
    store,
    getAll,
    getById,
    updateById,
    deleteById
}