import Api from "../Api";

const authorize = async (email, password) => {

    try {
        const res = await Api.post('/login', {
            email: email,
            password: password
        })
        console.log(res)
        if (res) {
            return res;
        }
    } catch (error) {
        console.log(error)
    }


}

const logout = () => {
    localStorage.getItem('tk_hsp', 'null');
}


export const AuthService = {
    authorize,logout
}