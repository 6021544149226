import Api from "../Api"


const getAll = async () => {

    try {
        const { data } = await Api.get('/usuarios')
        return data;
    } catch (error) {
        //console.log(error)
    }

}

const getById = async (idUsuario) => {
    try {
        const { data } = await Api.get('/usuarios/' + idUsuario)
        return data;
    } catch (error) {

    }

}


const store = async (dados) => {

    try {
        const data = await Api.post('/usuarios', dados);

        return data;
    } catch (error) {

    }

}


const updateById = async (idUsuario, dados) => {

    try {
        const data = await Api.post('/usuarios/'+idUsuario, dados);
        return data;
    } catch (error) {
        
    }

}



const deleteById = async (idUsuario) => {

    try {
        const { data } = await Api.delete('/usuarios/' + idUsuario)
        return data;
    } catch (error) {
        console.log(error)
    }
}

export const UsuariosService = {
    getAll,
    getById,
    deleteById,
    store,
    updateById

}