import { Button, Table } from "react-bootstrap";
import Template from "../../layout/Template";
import { useEffect, useState } from "react";
import { UsuariosService } from "../../services/api/Usuarios/UsuariosService";
import ModalSimNao from "../../components/ModalSimNao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare, faTrash } from "@fortawesome/free-solid-svg-icons";


const Usuarios = () => {

    const [listaUsers, setListUsers] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)

    const fetchUsers = async () => {
        const data = await UsuariosService.getAll()
 
        if (data.success) {
            setListUsers(data.data)
        }

    }

    useEffect(() => {
        fetchUsers()
    }, [])

    console.log(listaUsers.length)



    const handleDelete = (id) => {
        setIdDelete(id)
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
        setIdDelete(null)
    }
    const handleConfirmDelete = async () => {
        const usuario = await UsuariosService.deleteById(idDelete)
        handleClose();
        fetchUsers()
    }


    return (
        <>
            <Template>
                <h3>Usuários <Button className="ml-15" variant="primary" size="sm" href="/configuracoes/usuarios/cadEdit">Novo Usuário</Button> </h3>
                <hr></hr>

                {(listaUsers.length > 0) ?
                    <>
                        <Table bordered striped responsive size="sm">

                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>E-mail</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaUsers.map((item, key) => (

                                    <tr key={key}>
                                        <td>{item.nome}</td>
                                        <td>{item.email}</td>
                                        <td className="text-center">
                                            <Button variant="info" size="sm" title="Editar" className="mr-10" href={'/configuracoes/usuarios/cadEdit/' + item.id}><FontAwesomeIcon icon={faPencilSquare}/> Editar</Button>
                                            <Button variant="danger" size="sm" title="Ecluir" onClick={() => handleDelete(item.id)}><FontAwesomeIcon icon={faTrash}/>  Excluir</Button>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>

                        </Table>
                    </>

                    : <div className="alert alert-warning text-center">Sem usuários registrados</div>}

                <ModalSimNao handleClose={handleClose} handleConfirm={handleConfirmDelete} show={showModal}>
                    Tem certeza que deseja excluir este usuário?
                </ModalSimNao>

            </Template>

        </>
    )

}

export default Usuarios;