import React, { useEffect, useState } from "react";
import z from "zod"
import Template from "../../layout/Template";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UsuariosService } from "../../services/api/Usuarios/UsuariosService";

const UsuariosCadEdit = () => {


    const schema = z.object({
        name: z.string().min(3, "O nome deve ter no mínimo 3 letras"),
        password: z.coerce.string().nullable(),
        confirmPassword: z.coerce.string().nullable(),
        email: z.coerce.string().email('E-mail inválido'),
        alteraSenha: z.coerce.string().nullable(),
        foto: z.any().nullable(),
    }).refine((data) =>
    (data.alteraSenha.toString() == '1' && data.password == data.confirmPassword
        || data.alteraSenha.toString() == '0')
        , {
            message: 'As senhas não iguais!',
            path: ['password']
        })

    const navigate = useNavigate();
    const [usuario, setUsuario] = useState(null)
    const { idUsuario } = useParams()
    const [showSenha, setShowSenha] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        values: usuario
    })
    const watchAlteraSenha = watch('alteraSenha')
    const watchFoto = watch('foto')
    const [imgFoto, setImgFoto] = useState(null)



    useEffect(() => {

        const fetchUsuarios = async () => {
            const data = await UsuariosService.getById(idUsuario)
            console.log(data);
            if (data.success) {

                let userData = data.data
                setUsuario({
                    ...userData,
                    alteraSenha: "0",
                    name: userData.nome
                })
            }

        }
        if (idUsuario != null) {
            fetchUsuarios(idUsuario);
        } else {
            setUsuario({
                ...usuario,
                alteraSenha: "1"
            })
        }
    }, [])

    useEffect(() => {
        if (watchAlteraSenha === "0") {
            setShowSenha(false)
        } else {
            setShowSenha(true)
        }

    }, [watchAlteraSenha])

    useEffect(() => {
        if (watchFoto != undefined && watchFoto.length > 0) {
            const teste = URL.createObjectURL(watchFoto[0])
            setImgFoto(teste)

        }
    }, [watchFoto])

    const clearFoto = () => {
        if (imgFoto != null) {
            URL.revokeObjectURL(imgFoto)
        }
    }


    const onsubmit = async (dados) => {



        console.log(dados)

        let formData = new FormData();
        formData.append("foto", dados.foto[0]);

        formData.append("name", dados.name)
        formData.append("password", dados.password)
        formData.append("email", dados.email)
        formData.append("alteraSenha", dados.alteraSenha)


        console.log(formData)
        let dataForm = dados;
        delete dataForm.confirmPassword;



        if (idUsuario == null) {
            const user = await UsuariosService.store(formData);
            if (user.data.success) {
                clearFoto() //limpando a memoria
                navigate('/configuracoes/usuarios/')
            } else {
                alert(user.data.msg)
            }

        } else {


            const user = await UsuariosService.updateById(idUsuario, dataForm);
            if (user.data.success) {
                clearFoto() //limpando a memoria
                navigate('/configuracoes/usuarios/')
            }
            console.log(user)
        }
    }


    console.log(errors)
    return (
        <>
            <Template>



                <Container fluid>
                    <h4 className="text-info">Usuário - {(idUsuario != null) ? 'Editar' : 'Cadastrar'}</h4>
                    <hr></hr>
                    <Form>
                        <Row>

                            <Form.Group as={Col} sm={4}>
                                <Form.Label>Inserir foto:</Form.Label>
                                <Form.Control type="file" accept="image/png, image/gif, image/jpeg"
                                    {...register('foto')}
                                ></Form.Control>

                            </Form.Group>
                            <Form.Group as={Col} sm={5} >
                                <img src={imgFoto} style={{ maxWidth: '150px' }} />
                            </Form.Group>
                        </Row>



                        <Row>
                            <Form.Group as={Col} sm={4}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" placeholder="Nome"
                                    {...register('name')}
                                    isInvalid={!!(errors?.name)}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.name?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm={4}>
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="email" placeholder="E-mail"
                                    isInvalid={!!(errors?.email)}
                                    {...register('email')}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.email?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={6}>
                                <Form.Label className="mr-10">Alterar senha?</Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Não"
                                    value="0"
                                    {...register('alteraSenha')}
                                ></Form.Check>

                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Sim"
                                    value="1"
                                    {...register('alteraSenha')}
                                ></Form.Check>
                            </Form.Group>
                        </Row>
                        <Row style={{ display: (showSenha) ? '' : 'none' }}>
                            <Form.Group as={Col} sm={4}>
                                <Form.Label>Senha</Form.Label>
                                <Form.Control type="password"
                                    {...register('password')}
                                    isInvalid={!!(errors?.password)}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.password?.message}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group as={Col} sm={4}>
                                <Form.Label>Confirmar senha</Form.Label>
                                <Form.Control type="password"
                                    {...register('confirmPassword')}
                                    isInvalid={!!(errors?.confirmPassword)}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.confirmPassword?.message}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Row>

                        <div className="text-center">
                            <Button variant="danger" className="mr-10" href={'/configuracoes/usuarios'}>Cancelar</Button>
                            <Button variant="primary" className="" onClick={() => handleSubmit(onsubmit)()}> Salvar</Button>
                        </div>
                    </Form>


                </Container>


            </Template>


        </>
    )


}

export default UsuariosCadEdit