import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import ClienteList from "./ClienteList";
import Template from "../../layout/Template";
import { ClienteService } from "../../services/api/Clientes/ClienteService"
import { useEffect, useState } from "react";
import ModalSimNao from "../../components/ModalSimNao";

const Clientes = () => {

    const [paginacao, setPaginacao] = useState({
        currentPage: null,
        totalPages: null,
        nextPage: null,
        prevPage: null

    })

    const [showModalSN, setShowModalSN] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [search, setSearch] = useState('')
    const [timeDelay, setTimeDelay] = useState(null)
    const fecthCliente = async () => {

        const res = await ClienteService.getAll(search)
        console.log(res)
        if (res != undefined) {
            const data = res.data;
            setClientes(data)
            /*  setPaginacao({
                  currentPage:data.current_page,
                  totalPages: Math.ceil(data.total/data.per_page),
                  nextPage: (data.next_page_url),
                  prevPage:( data.prev_page_url)    
              })*/
        }
    }
    const [clientes, setClientes] = useState([])

    useEffect(() => {
        fecthCliente()
    }, [])



    const handleCloseModalSn = () => {
        setShowModalSN(false)
        setIdDelete(null)
    }
    const handleDelete = (idCliente) => {
        setIdDelete(idCliente)
        setShowModalSN(true)

    }
    const handleConfirmDelete = async () => {
        const data = await ClienteService.deleteById(idDelete)
        fecthCliente()
        console.log(data)
        setIdDelete(null)
        setShowModalSN(false)

    }


    const handleChangeBusca = (e) => {

        clearTimeout(timeDelay)

        setSearch(e.target.value)

        let time = setTimeout(function () {

            if (e.target.value.length >= 3) {
                fecthCliente()
               
            } else {
                fecthCliente()
            }

        }, 300)
        setTimeDelay(time);




    }
    return (

        <>
            {console.log(paginacao)}
            <Template>
                <h3>Clientes

                    <Button variant="primary" size="sm" style={{ marginLeft: '15px' }} href="/clientes/cadEdit">+ Adicionar</Button>
                </h3>

                <Row style={{ flexDirection: 'row-reverse' }}>
                    <Col xs={12} md={4}>
                        <Form.Control type="text" placeholder="Buscar  cliente" size="sm" onChange={(e) => handleChangeBusca(e)} />
                    </Col>
                </Row>
                <Table striped bordered hover size="sm" style={{ marginTop: '15px' }}>
                    <thead>
                        <tr>
                            <th>Cod.</th>
                            <th>Nome</th>
                            <th>Animais</th>
                            <th>Alerta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ClienteList listCliente={clientes} fecthCliente={fecthCliente} handleDelete={handleDelete} />
                    </tbody>
                </Table>


                <ModalSimNao show={showModalSN} handleClose={() => setShowModalSN(false)} handleConfirm={() => handleConfirmDelete()}>Tem certeza que deseja excluir este cliente?</ModalSimNao>
            </Template>

        </>


    )
}

export default Clientes;