import React, { useContext, useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import Api from "../../../services/api/Api";
import { AuthService } from "../../../services/api/Auth/AuthService";
import './login.css'
import { useForm } from 'react-hook-form'
import { AuthContext } from "../../../Contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {

    const { setAuth, auth, user, setUser } = useContext(AuthContext)


    const { register, handleSubmit, formState: { errors } } = useForm()

    const [msgError,setMsgError] = useState('')
    const [email, setEmail] = useState('');
    const navigate = useNavigate()


    const onSubmit = async (data) => {
        console.log(data)
        // const res = await AuthService.authorize('teste@teste.com', '123456')

        const res = await AuthService.authorize(data.email, data.senha)
        console.log(res);
        if(res ==  undefined){
             setMsgError("Usuário e/ou senha inválidos")
            return false;
        }
        if (res.data.status == 'success') {

            setUser(res.data.user)
            setAuth(true);
            localStorage.setItem('tk_hsp', res.data.authorization.token)

            navigate('/inicio')
        } 
    }



    return (
        <>

            <div id="container-login" className="">


                <div id="container-content">
                    <h4 className="text-center text-primary">Login</h4>

                    <Form.Group controlId="formEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="text"
                            {...register("email", { required: true })}
                        ></Form.Control>
                        {errors?.email?.type == 'required' && <p className="text-danger">E-mail obrigatório</p>}
                    </Form.Group>

                    <Form.Group controlId="formSenha" className="mt-15">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control type="password"
                            {...register("senha", { required: true, minLength: 5 })}></Form.Control>
                        {errors?.senha?.type == 'required' && <p className="text-danger">Senha obrigatória</p>}
                        {errors?.senha?.type == 'minLength' && <p className="text-danger">A senha deve ter no mínimo 7 caracteres</p>}
                    </Form.Group>

                    {(msgError != '')?<div class="alert alert-danger">
                        {msgError}</div>
                            :'' }

                    <div className="text-center ">
                        <Button variant="primary" className="mt-15" onClick={() => handleSubmit(onSubmit)()}>Entrar</Button>
                    </div>

                </div>


            </div>




        </>
    )

}
export default Login;