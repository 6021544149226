import React from 'react'
import Api from '../Api'



const getAll = async  (search,especiesId) =>{
    
try{
    
    let urlSearch =  new Array();
    
    if(search != null){
        urlSearch.push('search='+search)
    }
    if(especiesId != null){
        urlSearch.push('especiesId='+especiesId)
    }
    urlSearch =urlSearch.join('&')

    const {data} = await Api.get('/animais/racas?'+urlSearch);
    return data;

} catch(error){

}




}





export const AnimaisRacasService = {

getAll
}
